.footer {
  display: flex;
  flex-direction: column;
  gap: 1.4em;
  background: #dedddd;
  box-shadow: 0 0 1rem #dedddd;
  padding: 3rem 10vw;
}

.footer .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .flex .info {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
}

.footer .flex .info p {
  font-family: sans-serif;
  font-size: 1em;
}

.footer .flex .social {
  display: flex;
  align-items: center;
  gap: 0.3em;
}

.footer .flex .social a {
  font-size: 2.8em;
}

.footer .flex .social a:first-child {
  color: #4a74cf;
}

.footer .flex .social a:last-child {
  color: #ff9843;
}

.footer .column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer .column .copyright {
  font-family: sans-serif;
  color: #00000080;
}

.footer .column .copyright a {
  font-family: sans-serif;
  font-size: 1em;
  text-decoration: none;
  color: #222;
}

.footer .column .developedWithYoutube {
  display: flex;
  align-items: center;
  gap: 0.4em;
}

.footer .column .developedWithYoutube a {
  padding: 0;
  margin: 0;
}

.footer .column .developedWithYoutube a img {
  display: flex;
  align-items: center;

  width: 25em;
}

.footer .column .legal {
  display: flex;
  gap: 0.4em;
}

.footer .column .legal a {
  font-family: sans-serif;
  font-size: 1em;
  text-decoration: none;
  color: #222;
}

@media (max-width: 768px) {
  .footer {
    padding: 0.5rem;
  }
}
