:root {
  --primary-bg: #efeeee;
  --secondary: #4c4c4c;
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}
