.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #efeeee;
  box-shadow: 0rem 0rem 0.4rem #00000043;
  padding: 1.5rem 10vw;
}

.navbar_brand_wrapper {
  display: flex;
}

.navbar_brand {
  font-family: sans-serif;
  font-size: 2em;
  font-weight: normal;
  color: var(--secondary);
  text-decoration: none;
}

.navbar_links_wrapper {
  display: flex;
  align-items: center;
  gap: 1.5em;
  flex-wrap: wrap;
}

.navbar_item_wrapper {
  display: flex;
  align-items: center;
  gap: 1.4rem;
}

.navbar_item {
  list-style-type: none;
}

.navbar_item a {
  font-family: sans-serif;
  font-size: 1.2em;
  font-weight: normal;
  color: var(--secondary);
  text-decoration: none;
}

.navbar_search_wrapper {
  display: flex;
  align-items: center;
}

.navbar_search_wrapper input {
  padding: 0.55rem;
  border-radius: 1rem;
  border: none;
  box-shadow: 0rem 0rem 0.4rem #00000043;
}

.language {
  display: flex;
  align-items: center;
  gap: 0.6em;
}

.language img {
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar {
    gap: 0.5em;
    padding: 0.5rem;
  }
}
