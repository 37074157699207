.container {
  padding: 1.5rem 10vw;
}

.container .videoWrapper {
  display: flex;
  gap: 0.3em;
  cursor: pointer;
}

.container .videoWrapper .flex {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}

.container .videoWrapper .largeItem {
  position: relative;
  overflow: hidden;
}

.container .videoWrapper .largeItem img {
  display: flex;

  width: 100%;
  height: 100%;

  transition: 0.4s all ease-out;
}

.container .videoWrapper .largeItem:hover img {
  transition: 0.4 all ease-in;
  transform: scale(1.1);
}

.container .videoWrapper .largeItem span {
  position: absolute;
  bottom: 0;

  padding: 0.8rem;

  background: #00000094;
  color: var(--primary-bg);

  font-family: sans-serif;
  font-weight: normal;

  text-transform: capitalize;
  width: -moz-available;
}

.container .videoWrapper .regularItem {
  position: relative;
  overflow: hidden;
}

.container .videoWrapper .regularItem img {
  display: flex;

  width: 100%;
  height: 100%;

  transition: 0.4s all ease-out;
  transform: scale(1.36);
}

.container .videoWrapper .regularItem:hover img {
  transition: 0.4s all ease-in;
  transform: scale(1.5);
}

.container .videoWrapper .regularItem span {
  position: absolute;
  bottom: 0;

  padding: 0.5rem;

  background: #00000094;
  color: var(--primary-bg);

  font-family: sans-serif;
  font-weight: normal;

  text-transform: capitalize;
  width: -moz-available;
}

@media (max-width: 768px) {
  .container {
    padding: 0.5rem;
  }

  .container .videoWrapper {
    flex-wrap: wrap;
  }

  .container .videoWrapper .largeItem span {
    background: #000000ae;
  }

  .container .videoWrapper .regularItem span {
    background: #000000ae;
  }
}
