.container {
  display: flex;
  flex-direction: column;
  gap: 1.4em;
  padding: 3rem 10vw;
}

.container .titleWrapper {
  border-bottom: solid 0.1rem var(--secondary);
  padding-bottom: 0.6rem;
}

.container .titleWrapper h2 {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1em;
  color: var(--secondary);
  text-transform: uppercase;
}

.container .bodyWrapper {
  cursor: pointer;

  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.container .bodyWrapper p {
  font-family: sans-serif;
  font-weight: normal;
  color: var(--secondary);
}
