.container {
  display: flex;
  flex-direction: column;
  gap: 1.4em;
  padding: 3rem 10vw;
}

.container .titleWrapper {
  border-bottom: solid 0.1rem var(--secondary);
  padding-bottom: 0.6rem;
}

.container .titleWrapper h2 {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1em;
  color: var(--secondary);
  text-transform: uppercase;
}

.container .videoWrapper {
  cursor: pointer;

  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.container .videoWrapper .video {
  width: 22.65em;
  background: var(--primary-bg);
  box-shadow: 0rem 0rem 1rem #00000043;
}

.container .videoWrapper .video .imageWrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
}

.container .videoWrapper .video .imageWrapper img {
  position: absolute;

  width: 100%;
  height: auto;

  transform: scale(1.34);
  transition: 0.4s all ease-out;
}

.container .videoWrapper .video .imageWrapper img:hover {
  transform: scale(1.6);
}

.container .videoWrapper .video .imageWrapper span {
  position: absolute;
  bottom: 0;

  padding: 0.8rem;

  background: #00000094;
  color: var(--primary-bg);

  font-family: sans-serif;
  font-weight: normal;

  text-transform: capitalize;
  width: -moz-available;
}
