.container {
  display: flex;
  flex-direction: column;
  gap: 1.4em;
  padding: 1.5rem 10vw;
}

.container .iframe {
  width: 100%;
  height: 100vh;
}

.container .videoDetails {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
}

.container .videoDetails h2 {
  font-family: sans-serif;
  font-size: 2.4em;
  color: var(--secondary);
}

.container .videoDetails p {
  font-family: sans-serif;
  font-size: 1.2em;
  color: var(--secondary);
  line-height: 1.4;
  margin-bottom: 1rem;
}

.container .actions ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 1em;
}

.container .actions ul li svg {
  width: 3rem;
  height: 3rem;
  color: var(--secondary);
}
